import Web3 from "web3";
import Web3Modal from "web3modal";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from "@walletconnect/web3-provider";
import ethProvider from "eth-provider";
import WalletLink from 'walletlink'
import CryptoVenusContract from "../../contracts/CryptoVenus.json";
import { BehaviorRelay } from 'rxrelayjs';
import _Web3EthContract from 'web3-eth-contract';
const IPFSGatewayTools = require('@pinata/ipfs-gateway-tools/dist/node');
const gatewayTools = new IPFSGatewayTools();
const axios = require('axios');
const _ = require('lodash')

const CRYPTOVENUS_ADDR = '0x2f8C9bC5a0eD4E1d1F107D108F67F7508efF97Fa';
const ZERO_ADDR = '0x0000000000000000000000000000000000000000000000000000000000000000';
const APP_NAME = 'CryptoVenus NFT'
const APP_LOGO_URL = 'https://lh3.googleusercontent.com/3FglV0AwMmeGDeAK2qgjPkPsHaeipii7t27_QBj-BYcyTi5i8NMKyk2DxVQ0JeSVV_-YNwkCz2xBafsQj7Q3mOfd0__36uW-pR4s=s130'
const DEFAULT_ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/b80efbb2e248470c938b43cd34b90f1b'
const DEFAULT_CHAIN_ID = 1

const PINATA_GATEWAY = 'https://ipfs.filebase.io'

const Web3EthContract = _Web3EthContract as any;

export interface CryptoVenusData {
  id: number,
  tokenURI: string
}

export interface CryptoVenusInfo {
  allowances: Allowances,
  walletOfOwner: {[id: number]: CryptoVenusData}
}

export interface ListInfo {
  onList: boolean,
  proof: string[],
  root: string,
  leaf: string
}

export interface Allowances {
  allowlist?: ListInfo,
  giftlist?: ListInfo,
  giftMinted: number,
  totalMinted: number
}

export interface BlockchainInfo {
  web3: Web3
  networkId: number
  account: string
  owner: string
  mintPhase: MintPhase
  allowances: Allowances
  smartContract: any
  isDev: boolean
  isSoldOut: boolean
  cryptoVenusInfo?: CryptoVenusInfo
  isVenusInfoLoading: boolean
}

export interface Transaction {
  tx: any,
  name: string,
  value?: number
}

export enum TxState {
  PENDING, SUCCESS, FAILURE
}

export enum WalletProvider {
  METAMASK, COINBASE
}

export enum MintPhase {
  PENDING, PRESALE, GIFT, GIFT_PRESALE, PUBLIC
}

async function getImageURI(ipfsURI: string): Promise<string> {
  try {
    const metadataURI = _.includes(ipfsURI, 'http') ? ipfsURI : gatewayTools.convertToDesiredGateway(ipfsURI, PINATA_GATEWAY);
    let { data: { image } } = await axios.get(metadataURI)
    return gatewayTools.convertToDesiredGateway(image, PINATA_GATEWAY)
  } catch (err) {
    console.error(err)
    return 'image error'
  }
}

class BlockchainManager {
  // State holding variables
  private isReloadPaused: boolean = false
  private shouldReload: boolean = false
  private _web3: Web3 | undefined = undefined
  private _account: string | undefined = undefined
  private _smartContract: any | undefined = undefined
  private _networkId: number | undefined = undefined
  private _owner: string | undefined = undefined
  private _isDev: boolean | undefined = undefined
  private _isSoldOut: boolean | undefined = undefined
  private _mintPhase: MintPhase | undefined = undefined
  private _allowances: Allowances | undefined = undefined
  private _cryptoVenusInfo: CryptoVenusInfo | undefined = undefined
  private _venusStartedLoadingAt: Date | undefined = undefined

  private set web3(value: Web3 | undefined) {
    this._web3 = value;
    this.reloadState();
  }
  private set account(value: string | undefined) {
    this._account = value;
    this.reloadState();
  }
  private set smartContract(value: any | undefined) {
    this._smartContract = value;
    this.reloadState();
  }
  private set networkId(value: number | undefined) {
    this._networkId = value;
    this.reloadState();
  }
  private set owner(value: string | undefined) {
    this._owner = value;
    this.reloadState();
  }
  private set isDev(value: boolean | undefined) {
    this._isDev = value;
    this.reloadState();
  }
  private set isSoldOut(value: boolean | undefined) {
    this._isSoldOut = value;
    this.reloadState();
  }
  private set mintPhase(value: MintPhase | undefined) {
    this._mintPhase = value;
    this.reloadState();
  }
  private set allowances(value: Allowances | undefined) {
    this._allowances = value;
    this.reloadState();
  }
  private set cryptoVenusInfo(value: CryptoVenusInfo | undefined) {
    this._cryptoVenusInfo = value;
    this.reloadState();
  }
  private set venusStartedLoadingAt(value: Date | undefined) {
    this._venusStartedLoadingAt = value;
    this.reloadState();
  }

  rxBlockchainInfo: BehaviorRelay<BlockchainInfo | undefined>;

  constructor() {
    this.rxBlockchainInfo = new BehaviorRelay<BlockchainInfo | undefined>(undefined);
    this.connect = this.connect.bind(this);
  }

  private reloadState() {
    if (this.isReloadPaused) return;
    if (
      this._web3 !== undefined || 
      this._account !== undefined || 
      this._smartContract !== undefined || 
      this._networkId !== undefined || 
      this._owner !== undefined || 
      this._isDev !== undefined
    ) { 
      this.rxBlockchainInfo.next({
        web3: this._web3 as Web3,
        account: this._account as string,
        smartContract: this._smartContract as any,
        networkId: this._networkId as number,
        mintPhase: this._mintPhase as MintPhase,
        allowances: this._allowances as Allowances,
        owner: this._owner as string,
        isDev: this._isDev as boolean,
        isSoldOut: this._isSoldOut as boolean,
        cryptoVenusInfo: this._cryptoVenusInfo,
        isVenusInfoLoading: this._venusStartedLoadingAt !== undefined
      })
    } else {
      this.rxBlockchainInfo.next(undefined);
    }
  }

  getCoinbaseEth() {
    const walletLink = new WalletLink({
      appName: APP_NAME,
      appLogoUrl: APP_LOGO_URL,
      darkMode: false
    })

    return walletLink.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID)
  }

  getMetamaskEth() {
    const { ethereum } = window as { ethereum?: any };
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (!metamaskIsInstalled) throw new Error('Metamask not installed')
    return ethereum
  }

  async connect(auto: boolean) {
    this.isReloadPaused = true;

    const INFURA_ID = 'b80efbb2e248470c938b43cd34b90f1b';

    const getProviderOptions = () => {
      const infuraId = INFURA_ID;
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId
          }
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK, // Required
          options: {
            appName: "CryptoVenus", // Required
            infuraId, // Required
            rpc: "", // Optional if `infuraId` is provided; otherwise it's required
            chainId: 1, // Optional. It defaults to 1 if not provided
            darkMode: false // Optional. Use dark theme, defaults to false
          }
        },
        frame: {
          package: ethProvider // required
        }
      };
      return providerOptions;
    };
    
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: getProviderOptions() // required
    });
    web3Modal.clearCachedProvider()
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    this.web3 = web3;
    Web3EthContract.setProvider(provider);

    const accounts = await web3.eth.getAccounts();
    
    if (auto && (!accounts || accounts.length === 0)) return; // Don't connect.

    try {
      // Account
      const account = ((await provider.request({
        method: "eth_requestAccounts",
      })) as string[])[0]
      this.account = account;

      // Network ID
      this.networkId = await provider.request({
        method: "net_version",
      });

      // Smart Contracts
      const smartContract = new Web3EthContract(
        CryptoVenusContract.abi,
        CRYPTOVENUS_ADDR
      );
      this.smartContract = smartContract

      // Owner
      const owner = await smartContract.methods.owner().call();
      this.owner = owner;

      const totalSupply = await smartContract.methods.totalSupply().call();
      const maxSupply = await smartContract.methods.MAX_SUPPLY().call();
      this.isSoldOut = totalSupply === maxSupply

      // Is Dev
      this.isDev = owner === web3.utils.toChecksumAddress(account) 
      this.mintPhase = await this.getMintPhase()
      this.allowances = await this.getAllowances()

      provider.on("accountsChanged", async () => {
        window.location.reload();
      });
      provider.on("chainChanged", () => {
        window.location.reload();
      });

      await this.reloadVenusInfo();

      this.shouldReload = true;
    } catch (err) {
      console.error(err);
      throw new Error('Something went wrong')
    } finally {
      this.isReloadPaused = false;
      if (this.shouldReload) {
        this.shouldReload = false;
        this.reloadState();
      }
    }
  }

  async reloadVenusInfo() {
    const account = this._account;
    const cryptoVenusContract = this._smartContract

    if (!account || !cryptoVenusContract) throw new Error('Not connected!');

    const venusIDS = await cryptoVenusContract.methods.walletOfOwner(account).call();

    (async (context: any) => {
      let loadingDate = new Date()

      context.venusStartedLoadingAt = loadingDate

      for (let id of venusIDS) {
        let venusData = {
          id: id, 
          tokenURI: await getImageURI(await cryptoVenusContract.methods.tokenURI(id).call())
        }

        let walletOfOwner = Object.assign({}, context._cryptoVenusInfo?.walletOfOwner || {})

        walletOfOwner[id] = venusData

        context.cryptoVenusInfo = {
          walletOfOwner
        }
      }

      if (loadingDate === context._venusStartedLoadingAt) context.venusStartedLoadingAt = undefined;
    })(this)
  }

  async getAllowances() {
    const account = this._account;
    const venusContract: any = this._smartContract;

    if (!venusContract || !account) throw new Error('Not connected!');

    const giftAddressToMinted = await venusContract.methods.giftAddressToMinted(Web3.utils.toChecksumAddress(account)).call()
    const addressToMinted = await venusContract.methods.addressToMinted(Web3.utils.toChecksumAddress(account)).call()

    const {data} = await axios.get('https://mint.cryptovenusnft.com/api/allowances', { params: { address: account }});
    //const {data} = await axios.get('http://127.0.0.1:5001/api/allowances', { params: { address: Web3.utils.toChecksumAddress(account) }});
    
    return {
      allowlist: data.allowances.allowlist,
      giftlist: data.allowances.giftlist,
      totalMinted: parseInt(addressToMinted),
      giftMinted: parseInt(giftAddressToMinted)
    };
  }

  async getMintPhase() {
    const account = this._account;
    const venusContract: any = this._smartContract;

    if (!venusContract || !account) throw new Error('Not connected!');

    const isAllowlistRootSet = (await venusContract.methods.allowlistMerkleRoot().call()) !== ZERO_ADDR;
    const isGiftlistRootSet = (await venusContract.methods.giftlistMerkleRoot().call()) !== ZERO_ADDR;
    const isPublicMintOpen = await venusContract.methods.isPublicMintOpen().call();
    
    if (isPublicMintOpen) return MintPhase.PUBLIC;

    if (isAllowlistRootSet === true && isGiftlistRootSet === true) return MintPhase.GIFT_PRESALE;
    else if (isAllowlistRootSet === false && isGiftlistRootSet === true) return MintPhase.GIFT;
    else if (isAllowlistRootSet === true && isGiftlistRootSet === false) return MintPhase.PRESALE;
    else return MintPhase.PENDING;
  }

  async setGiftListMerkleRoot(root: string) {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');
    
    let txHashes = await this.sendSafe([
      {
        tx: venusContract.methods.setGiftListMerkleRoot(root),
        name: `setGiftListMerkleRoot`
      }
    ])

    await this.waitForTransactionSuccess(txHashes[0].transactionHash)
    await this.reloadVenusInfo()
  }

  async setAllowlistMerkleRoot(root: string) {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');
    
    let txHashes = await this.sendSafe([
      {
        tx: venusContract.methods.setAllowlistMerkleRoot(root),
        name: `setAllowlistMerkleRoot`
      }
    ])

    await this.waitForTransactionSuccess(txHashes[0].transactionHash)
    await this.reloadVenusInfo()
  }

  async togglePublicSale() {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');
    
    let txHashes = await this.sendSafe([
      {
        tx: venusContract.methods.togglePublicSale(),
        name: `togglePublicSale`
      }
    ])

    await this.waitForTransactionSuccess(txHashes[0].transactionHash)
    await this.reloadVenusInfo()
  }

  async devMint(count: number, to: string) {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');
    
    let txHashes = await this.sendSafe([
      {
        tx: venusContract.methods.devMint(count, to),
        name: `devMint`
      }
    ])

    await this.waitForTransactionSuccess(txHashes[0].transactionHash)
    await this.reloadVenusInfo()
  }

  async withdraw() {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');
    
    await this.sendSafe([
      {
        tx: venusContract.methods.withdraw(account),
        name: `withdraw`
      }
    ])
  }

  async giftlistMint(amount: number, proof: any) {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');
    
    let txHashes = await this.sendSafe([
      {
        tx: venusContract.methods.giftlistMint(amount, proof),
        name: 'giftlistMint'
      }
    ])

    await this.waitForTransactionSuccess(txHashes[0].transactionHash)
    await this.reloadVenusInfo()
  }

  async allowlistMint(amount: number, proof: any) {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');

    const price = await venusContract.methods.allowlistPrice().call()
    
    let txHashes = await this.sendSafe([
      {
        tx: venusContract.methods.allowlistMint(amount, proof),
        name: 'allowlistMint',
        value: price * amount
      }
    ])

    await this.waitForTransactionSuccess(txHashes[0].transactionHash)
    await this.reloadVenusInfo()
  }

  async publicMint(amount: number) {
    const account = this._account;
    const blockchainInfo = this.rxBlockchainInfo.value;
    const venusContract: any = blockchainInfo?.smartContract;

    if (!blockchainInfo || !venusContract || !account) throw new Error('Not connected!');

    const price = await venusContract.methods.mintPrice().call()
    
    let txHashes = await this.sendSafe([
      {
        tx: venusContract.methods.publicMint(amount),
        name: 'publicMint',
        value: price * amount
      }
    ])

    await this.waitForTransactionSuccess(txHashes[0].transactionHash)
    await this.reloadVenusInfo()
  }

  // GENERIC UTILITIES

  private async waitForTransactionSuccess(txHash: string) {
    const web3 = this._web3;
    if (!web3) throw new Error('Disconnected');
    const transactionReceiptAsync = function(resolve: any, reject: any) {
      web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
        if (error) {
          reject(error);
        } else if (receipt == null) {
          setTimeout(
            () => transactionReceiptAsync(resolve, reject),
            500
          );
        } else {
          if (receipt.status === true) {
            resolve(true)
          } else {
            reject(new Error('Transaction failed. Was reverted.'));
          }
        }
      });
    };

    return new Promise(transactionReceiptAsync);
  };

  async sendSafe(txs: Transaction[]): Promise<any[]> {
    const account = this._account;
    const web3 = this._web3;

    if (!account || !web3) throw new Error('Not connected');

    let nonce = (await web3.eth.getTransactionCount(account));

    let txHashes: any[] = [] 

    for (let tx of txs) {
      if (this._account !== account) throw new Error('Account switched during transactions!');
      console.log(`Starting tx ${tx.name}`);
      let options: any = { from: account, nonce };
      if (tx.value !== undefined) options.value = tx.value;
      options.gas = await tx.tx.estimateGas(options);
      txHashes.push(await tx.tx.send(options));
      nonce += 1;
      console.log(`Finshed tx ${tx.name}`);
    }

    return txHashes
  }

}

export default new BlockchainManager();