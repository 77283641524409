import React from 'react';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";

// Component Imports
import CacheBuster from './CacheBuster';
import Home from './Home';
import JDAppComponent from './JDAppComponent';
import BlockchainManager, { BlockchainInfo } from '../utils/blockchain';
import { Footer } from './Footer';
import { Header } from './Header';
import { Router404 } from './Route404';

export enum AppTheme {
  LIGHT = 'light-mode',
  DARK = 'dark-mode'
}

export enum NavigationOption {
  
}

function DevPanel(props: { blockchainInfo: BlockchainInfo, toggleDevPanel: () => void }) {
  return (
    <div className='absolute w-full h-full z-50'>
      <div className='relative w-full h-full'>
        <div className='flex flex-col items-center justify-center bg-white'>
          <h1>VENUS ADMIN</h1>
          <h1>Info</h1>
          <div className='flex flex-row items-center justify-center flex-wrap'>
            <button onClick={() => BlockchainManager.reloadVenusInfo()}>Wallet Info</button>
            <button onClick={() => BlockchainManager.togglePublicSale()} style={{marginLeft: '20px'}}>Toggle Public Sale</button>
          </div>
          <h3>Control</h3>
          <div className='flex flex-row items-center justify-center flex-wrap'>
            <button onClick={() => BlockchainManager.setGiftListMerkleRoot("0xd12d5021d9b5a2a7d0ef5e764812fcda0ff9ddb334cb68ec5e940597594f76fc")}>Set Gift Root</button>
            <button onClick={() => BlockchainManager.setAllowlistMerkleRoot("0x3cc7118e71132d80e8e2f800105464cedcaab45fcaba66c338df1209c89d8b81")} style={{marginLeft: '20px'}}>Set Allow Root</button>
          </div>
          <h3>Mint</h3>
          <div className='flex flex-row items-center justify-center flex-wrap'>
            <button onClick={() => BlockchainManager.devMint(9, '0xFFcf8FDEE72ac11b5c542428B35EEF5769C409f0')} style={{marginLeft: '20px'}}>Dev Mint</button>
            <button onClick={() => BlockchainManager.publicMint(9)} style={{marginLeft: '20px'}}>Public Mint</button>
          </div>
          <button onClick={props.toggleDevPanel}>CLOSE</button>
        </div>
      </div>
    </div>
  )
}

type AppProps = RouteComponentProps<{}>

export type AppInterface = {
  setTheme: (theme: AppTheme) => void,
  didSelectNavigationOption: (option: NavigationOption) => void,
  blockchainInfo: BlockchainInfo | undefined,
  isDevPanelOpen: boolean,
  toggleDevPanel: () => void
}

interface ConnectionInfo {
  account: string,
  isDev: boolean
}

export type AppState = {
  blockchainInfo: BlockchainInfo | undefined
  theme: AppTheme,
  modal: any | undefined,
  isInitialized: boolean,
  initializationError: Error | undefined,
  isDevPanelOpen: boolean,
  connectionInfo: ConnectionInfo | undefined
}

class App extends JDAppComponent<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      blockchainInfo: undefined,
      theme: AppTheme.LIGHT,
      modal: undefined,
      isInitialized: false,
      initializationError: undefined,
      isDevPanelOpen: false,
      connectionInfo: undefined
    };
    
    // Bindings
    this.setTheme = this.setTheme.bind(this);
    this.toggleDevPanel = this.toggleDevPanel.bind(this);
    this.didSelectNavigationOption = this.didSelectNavigationOption.bind(this);
  }

  setTheme(theme: AppTheme) {
    this.setState({ theme });

    document.body.className = `bg-femme ${theme}`;
  }

  didSelectNavigationOption(option: NavigationOption) {

  }


  toggleDevPanel() {
    this.setState({ isDevPanelOpen: !this.state.isDevPanelOpen })
  }

  async componentDidMount() {
    this.subscriptions.push(
      BlockchainManager
        .rxBlockchainInfo
        .subscribe((blockchainInfo) => {
          this.setState({blockchainInfo})
        })
    )

    let initializationError: Error | undefined = undefined;

    this.setState({ isInitialized: true, initializationError })
        // TODO - reenable this when we keep track of last wallet
    // try {
    //   await BlockchainManager.connect(true);
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   this.setState({ isInitialized: true, initializationError })
    // }
  }

  // Render

  render() {
    let appInterface: AppInterface = {
      blockchainInfo: this.state.blockchainInfo,
      setTheme: this.setTheme,
      toggleDevPanel: this.toggleDevPanel,
      didSelectNavigationOption: this.didSelectNavigationOption,
      isDevPanelOpen: this.state.isDevPanelOpen
    }

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;

          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          
          return (
            <div className=' relative flex flex-col max-h-screen min-h-screen content-center static'>
              { (this.state.modal) ? (this.state.modal) : (<div/>) }
              {
                (appInterface.isDevPanelOpen && appInterface.blockchainInfo) ? (
                  <DevPanel blockchainInfo={appInterface.blockchainInfo} toggleDevPanel={appInterface.toggleDevPanel}/>
                ) : (
                  <div/>
                )
              }
              <Header main={true} appInterface={appInterface} toggleDevPanel={appInterface.toggleDevPanel}/>
              {
                (this.state.isInitialized) ? (
                  <Switch>
                    <Route exact path="/"
                      render={(props) => (
                        <Home appInterface={appInterface} {...props}/>
                      )}
                    />
                    <Route path="*"
                      render={(props) => (
                        <Router404 appInterface={appInterface} {...props}/>
                      )}
                    />
                  </Switch>
                ) : (
                  <div className='flex flex-col flex-grow justify-center items-center w-full h-full bg-femme'>
                    <h1 style={{textAlign:'center', marginBottom: '40px'}}>LOADING DATA</h1>
                    <ClipLoader color={'#000000'} loading={true} css={'margin: auto; display: block;'} size={125} />
                  </div>
                )
              }
              <Footer/>
            </div>
          )
        }}
      </CacheBuster>
    );
  }
}

export default withRouter(App);