import { Component } from 'react';
import { Subscription } from 'rxjs';

export default class JDAppComponent<P = {}, S = {}, SS = any> extends Component<P, S, SS> {
  setStateAsync = (update: {}) => new Promise(resolve => {
    if (!this._isMounted) return resolve();
    this.setState(update, resolve)
  });
  subscriptions: Subscription[] = [];
  private _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}