import React from 'react';
import { AppInterface } from './App';
import Etherscan from '../../img/etherscan.svg'
import Home from '../../img/home.svg'
import OpenSea from '../../img/opensea.png'

export function Header(props: { main: boolean, appInterface: AppInterface, toggleDevPanel: () => void }) {
    return (
      <header className='flex flex-row h-24 bg-black items-center justify-center'>
        <a href='https://www.cryptovenusnft.com/' className='pr-4 pl-4 text-white text-3xl font-epitaph'>CryptoVenus 💘</a>
        <div className='flex-grow'/>
        <a className='mr-6' href='https://etherscan.io/address/0x2f8c9bc5a0ed4e1d1f107d108f67f7508eff97fa'>
          <img className='w-8 h-8' src={Etherscan} alt='etherscan'/>
        </a>
        <a className='mr-6' href='https://opensea.io/collection/cryptovenusnft'>
          <img className='w-8 h-8 fill-white' src={OpenSea} alt='opensea'/>
        </a>
        <a className='mr-4' href='https://www.cryptovenusnft.com/'>
          <img className='w-8 h-8 fill-white' src={Home} alt='home'/>
        </a>
        {
          (props.appInterface.blockchainInfo?.isDev === true) ? (
            <button 
              style={{paddingTop: "1px"}} 
              onClick={props.toggleDevPanel} 
              className="btn btn-link btn-nohighlight"
            >DEV</button>
          ) : (
            <div/>
          )
        }
      </header>
    )
}