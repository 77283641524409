import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

// Component Imports
import App from './react/components/App';

// CSS Imports

import { unregister } from './react/utils/registerServiceWorker';

import './index.css';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

const updateViewportHeight = () => {
  let vh = window.innerHeight  * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

window.addEventListener('resize', updateViewportHeight);

updateViewportHeight();
unregister();