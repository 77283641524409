import React, { Component } from 'react';
import { AppInterface, AppTheme } from './App';
import Blockchain, { MintPhase, TxState } from '../utils/blockchain';

type HomeProps = {
  appInterface: AppInterface
}

enum HomeViewType {
  MINT, COLLECTION
}

function ViewCollectionButton(props: { viewCollection: () => void }) {
  return (
    <button className='font-epitaph cursor-pointer text-white bg-black pl-4 pr-4 pb-2 pt-2 mt-2 text-md rounded-lg' onClick={props.viewCollection}>View your Collection</button>
  )
}

function forceDownload(blob: any, filename: any) {
  var a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

// Current blob size limit is around 500MB for browsers
function downloadResource(url: any, filename: any) {
  if (!filename) filename = url.split('\\').pop().split('/').pop();
  fetch(url, {
      headers: new Headers({
        'Origin': window.location.origin
      }),
      mode: 'cors'
    })
    .then(response => response.blob())
    .then(blob => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
}

class Home extends Component<HomeProps, { txState: TxState | undefined, numberToMint: number, errorMessage: string | undefined, viewType: HomeViewType }> {
  constructor(props: any) {
    super(props)

    this.state = {
      txState: undefined,
      errorMessage: undefined,
      numberToMint: 0,
      viewType: HomeViewType.MINT
    }

    this.giftlistMint = this.giftlistMint.bind(this);
    this.allowlistMint = this.allowlistMint.bind(this);
    // this.connectCoinbase = this.connectCoinbase.bind(this);
    // this.connectMetamask = this.connectMetamask.bind(this);
    this.connectWallet = this.connectWallet.bind(this);
    this.getAddress = this.getAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAllowSubmit = this.handleAllowSubmit.bind(this);
    this.handlePublicSubmit = this.handlePublicSubmit.bind(this);
  }

  componentDidMount() {
    this.props.appInterface.setTheme(AppTheme.DARK)
    //this.startCountdown();
  }

  // private async connectCoinbase() {
  //   try {
  //     await Blockchain.connect(false, WalletProvider.COINBASE);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  // private async connectMetamask() {
  //   try {
  //     await Blockchain.connect(false, WalletProvider.METAMASK);
  //   } catch (err) {
  //     if (err instanceof Error && err.message.includes('Metamask not installed')) {
  //       window.location.href = 'https://metamask.app.link/dapp/mint.cryptovenusnft.com'
  //     }
  //     console.error(err);
  //   }
  // }

  private async connectWallet() {
    try {
      await Blockchain.connect(false);
    } catch (err) {
      if (err instanceof Error && err.message.includes('Metamask not installed')) {
        window.location.href = 'https://metamask.app.link/dapp/mint.cryptovenusnft.com'
      }
      console.error(err);
    }
  }

  private async giftlistMint() {
    try {
      let proof = this.props.appInterface.blockchainInfo?.allowances.giftlist?.proof;
      this.setState({txState: TxState.PENDING, errorMessage: undefined})
      await Blockchain.giftlistMint(1, proof);
      this.setState({txState: TxState.SUCCESS})
    } catch (err) {
      this.setState({txState: TxState.FAILURE, errorMessage: (err as any).message})
      console.error((err as any).message);
    }
  }

  private async publicMint() {
    try {
      let amount = this.state.numberToMint;
      if (amount <= 0 || amount > 10) throw new Error("Must mint between 1 and 10 Goddesses!");
      this.setState({txState: TxState.PENDING, errorMessage: undefined})
      await Blockchain.publicMint(amount);
      this.setState({txState: TxState.SUCCESS})
    } catch (err) {
      this.setState({txState: TxState.FAILURE, errorMessage: (err as any).message})
      console.error((err as any).message);
    }
  }

  private async allowlistMint() {
    try {
      let amount = this.state.numberToMint;
      if (amount <= 0 || amount > 10) throw new Error("Must mint between 1 and 10 Goddesses!");
      let proof = this.props.appInterface.blockchainInfo?.allowances.allowlist?.proof;
      this.setState({txState: TxState.PENDING, errorMessage: undefined})
      await Blockchain.allowlistMint(amount, proof);
      this.setState({txState: TxState.SUCCESS})
    } catch (err) {
      this.setState({txState: TxState.FAILURE, errorMessage: (err as any).message})
      console.error((err as any).message);
    }
  }

  private getAddress() {
    const account = this.props.appInterface.blockchainInfo?.account;
    return account ? `${account.substring(0,10)}...${account.substring(account.length - 9, account.length - 1)}` : '';
  }

  handleChange(event: any) {
    this.setState({numberToMint: event.target.value});
  }

  handleAllowSubmit(event: any) {
    event.preventDefault();

    this.allowlistMint()
  }

  handlePublicSubmit(event: any) {
    event.preventDefault();

    this.publicMint()
  }

  render() {
    let numbers = [];
    for (let i = 0; i <= (10 - (this.props.appInterface.blockchainInfo?.allowances.totalMinted ?? 0)); i++) {
      numbers.push(i);
    }

    return (
      <div className="flex flex-col flex-grow justify-center items-center w-full bg-hero bg-top bg-cover">
        <div className="flex flex-col bg-white items-center justify-center p-12 rounded-lg m-8" style={{maxHeight: '80vh'}}>
          <h1 className="font-epitaph text-center text-3xl">{(this.state.viewType === HomeViewType.MINT) ? '💘 10,000 Goddesses Available 💘' : '💘 Your Collection 💘'}</h1>
          { (this.state.viewType === HomeViewType.MINT) ? (<div/>) : (<h3>{Object.keys(this.props.appInterface.blockchainInfo?.cryptoVenusInfo?.walletOfOwner || {}).length} collected</h3>) }
          {
            (this.props.appInterface.blockchainInfo) ? (
              (() => {
                switch (this.state.viewType) {
                  case HomeViewType.MINT:
                    let { mintPhase, allowances, isSoldOut } = this.props.appInterface.blockchainInfo;
                    if (isSoldOut) {
                      return (
                        <div className="flex flex-col items-center justify-center">
                          <h1 className="text-lg font-thin mt-8">CryptoVenus NFT is Sold Out!</h1>
                          <h1 className="text-lg font-thin">Thank you for your support!</h1>
                          <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                        </div>
                      );
                    }

                    switch (mintPhase) {
                      case (MintPhase.PUBLIC):
                        if (allowances.giftlist?.onList === true && allowances.giftMinted === 0) {
                          return (
                            <div className="flex flex-col items-center justify-center">
                              <h1 className="text-lg font-thin mt-8">You have a Goddess waiting for you!</h1>
                              <h1 className="text-lg font-thin">Click below to claim</h1>
                              <button disabled={this.state.txState === TxState.PENDING} className='font-epitaph text-white bg-black p-8 mt-12 text-3xl rounded-lg' onClick={() => this.giftlistMint()}>Free Mint</button>
                              { (this.state.txState === TxState.PENDING) ? (<p className='mt-4 text-black'>Submitted. Please wait.</p>) : <div/>}
                              { (this.state.txState === TxState.FAILURE) ? (<p className='mt-4 text-red-400'>{ (this.state.errorMessage) ? this.state.errorMessage : 'Something went wrong. Try again.' }</p>) : <div/>}
                              { (this.state.txState === TxState.SUCCESS) ? (<p className='mt-4 text-green-400'>Success</p>) : <div/>}
                              <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                              <ViewCollectionButton viewCollection={() => { this.setState({ viewType: HomeViewType.COLLECTION }) }}/>
                            </div>
                          )
                        } else if (allowances.allowlist?.onList === true) {
                          return (
                            <div className="flex flex-col items-center justify-center">
                              <h1 className="text-lg font-thin mt-8">Allowlist Price 0.08 ETH</h1>
                              <h1 className="text-lg font-thin">Limit of 10 tokens per wallet</h1>
                              <form onSubmit={this.handleAllowSubmit} className="flex flex-row mt-12 items-center align-center justify-center">
                                <select className='text-3xl cursor-pointer border-2 p-3 rounded-md black border-widthfont-epitaph mr-8' onChange={this.handleChange} value={this.state.numberToMint}>
                                  {numbers.map(val => {
                                    return (<option  className='text-xl font-epitaph' key={val} value={val}>{val}</option>)
                                  })}
                                </select>
                                <input disabled={this.state.numberToMint === 0 || this.state.txState === TxState.PENDING} className={`font-epitaph bg-black text-white text-3xl p-6 rounded-xl ${(this.state.numberToMint !== 0) ? 'cursor-pointer' : 'opacity-30'}`} type="submit" value="Mint"/>
                              </form>
                              { (this.state.txState === TxState.PENDING) ? (<p className='mt-4 text-black'>Submitted. Please wait.</p>) : <div/>}
                              { (this.state.txState === TxState.FAILURE) ? (<p className='mt-4 text-red-400'>{ (this.state.errorMessage) ? this.state.errorMessage : 'Something went wrong. Try again.' }</p>) : <div/>}
                              { (this.state.txState === TxState.SUCCESS) ? (<p className='mt-4 text-green-400'>Success</p>) : <div/>}
                              <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                              <ViewCollectionButton viewCollection={() => { this.setState({ viewType: HomeViewType.COLLECTION }) }}/>
                            </div>
                          )
                        } else {
                          return (
                            <div className="flex flex-col items-center justify-center">
                              <h1 className="text-lg font-thin mt-8">One CryptoVenus NFT is 0.1 ETH</h1>
                              <h1 className="text-lg font-thin">Limit of 10 tokens per wallet</h1>
                              <form onSubmit={this.handlePublicSubmit} className="flex flex-row mt-12 items-center align-center justify-center">
                                <select className='text-3xl cursor-pointer border-2 p-3 rounded-md black border-widthfont-epitaph mr-8' onChange={this.handleChange} value={this.state.numberToMint}>
                                  {numbers.map(val => {
                                    return (<option  className='text-xl font-epitaph' key={val} value={val}>{val}</option>)
                                  })}
                                </select>
                                <input disabled={this.state.numberToMint === 0 || this.state.txState === TxState.PENDING} className={`font-epitaph bg-black text-white text-3xl p-6 rounded-xl ${(this.state.numberToMint !== 0) ? 'cursor-pointer' : 'opacity-30'}`} type="submit" value="Mint"/>
                              </form>
                              { (this.state.txState === TxState.PENDING) ? (<p className='mt-4 text-black'>Submitted. Please wait.</p>) : <div/>}
                              { (this.state.txState === TxState.FAILURE) ? (<p className='mt-4 text-red-400'>{ (this.state.errorMessage) ? this.state.errorMessage : 'Something went wrong. Try again.' }</p>) : <div/>}
                              { (this.state.txState === TxState.SUCCESS) ? (<p className='mt-4 text-green-400'>Success</p>) : <div/>}
                              <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                              <ViewCollectionButton viewCollection={() => { this.setState({ viewType: HomeViewType.COLLECTION }) }}/>
                            </div>
                          )
                        }
                      case (MintPhase.PRESALE):
                      case (MintPhase.GIFT_PRESALE):
                      case (MintPhase.GIFT):
                        if (allowances.giftlist?.onList === true && (mintPhase === MintPhase.GIFT || mintPhase === MintPhase.GIFT_PRESALE) && allowances.giftMinted === 0) {
                          return (
                            <div className="flex flex-col items-center justify-center">
                              <h1 className="text-lg font-thin mt-8">You have a Goddess waiting for you!</h1>
                              <h1 className="text-lg font-thin">Click below to claim</h1>
                              <button disabled={this.state.txState === TxState.PENDING} className='font-epitaph text-white bg-black p-8 mt-12 text-3xl rounded-lg' onClick={() => this.giftlistMint()}>Free Mint</button>
                              { (this.state.txState === TxState.PENDING) ? (<p className='mt-4 text-black'>Submitted. Please wait.</p>) : <div/>}
                              { (this.state.txState === TxState.FAILURE) ? (<p className='mt-4 text-red-400'>{ (this.state.errorMessage) ? this.state.errorMessage : 'Something went wrong. Try again.' }</p>) : <div/>}
                              { (this.state.txState === TxState.SUCCESS) ? (<p className='mt-4 text-green-400'>Success</p>) : <div/>}
                              <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                              <ViewCollectionButton viewCollection={() => { this.setState({ viewType: HomeViewType.COLLECTION }) }}/>
                            </div>
                          )
                        } else if (allowances.allowlist?.onList === true && (mintPhase === MintPhase.PRESALE || mintPhase === MintPhase.GIFT_PRESALE)) {
                          return (
                            <div className="flex flex-col items-center justify-center">
                              <h1 className="text-lg font-thin mt-8">You have access to the Pre-Sale!</h1>
                              <h1 className="text-lg font-thin">Price: 0.08 ETH per Goddess</h1>
                              <form onSubmit={this.handleAllowSubmit} className="flex flex-row mt-12 items-center align-center justify-center">
                                <select className='text-3xl cursor-pointer border-2 p-3 rounded-md black border-widthfont-epitaph mr-8' onChange={this.handleChange} value={this.state.numberToMint}>
                                  {numbers.map(val => {
                                    return (<option  className='text-xl font-epitaph' key={val} value={val}>{val}</option>)
                                  })}
                                </select>
                                <input disabled={this.state.numberToMint === 0 || this.state.txState === TxState.PENDING} className={`font-epitaph bg-black text-white text-3xl p-6 rounded-xl ${(this.state.numberToMint !== 0) ? 'cursor-pointer' : 'opacity-30'}`} type="submit" value="Mint"/>
                              </form>
                              { (this.state.txState === TxState.PENDING) ? (<p className='mt-4 text-black'>Submitted. Please wait.</p>) : <div/>}
                              { (this.state.txState === TxState.FAILURE) ? (<p className='mt-4 text-red-400'>{ (this.state.errorMessage) ? this.state.errorMessage : 'Something went wrong. Try again.' }</p>) : <div/>}
                              { (this.state.txState === TxState.SUCCESS) ? (<p className='mt-4 text-green-400'>Success</p>) : <div/>}
                              <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                              <ViewCollectionButton viewCollection={() => { this.setState({ viewType: HomeViewType.COLLECTION }) }}/>
                            </div>
                          )
                        } else {
                          return (
                            <div className="flex flex-col items-center justify-center">
                              <h1 className="text-lg font-thin mt-8">Sorry, you don't have access to the Pre-Sale!</h1>
                              <h1 className="text-lg font-thin">Check back 2/24 for the Public Mint!</h1>
                              <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                            </div>
                          )
                        }
                      default:
                        return (
                          <div className="flex flex-col items-center justify-center">
                            <h1 className="text-lg font-thin mt-8">Pre-Sale hasn't started yet!</h1>
                            <h1 className="text-lg font-thin">Check back 2/22 @ 2 EST</h1>
                            <p className='font-epitaph mt-12 font-thin'>Connected: {this.getAddress()}</p>
                          </div>
                        );
                    }
                  case HomeViewType.COLLECTION:
                    return (
                      <div className='flex flex-col justify-center items-center overflow-y-hidden'>
                        <div className='flex flex-row flex-wrap overflow-y-auto'>
                          {
                            Object.values(this.props.appInterface.blockchainInfo.cryptoVenusInfo?.walletOfOwner || {})
                              .map((goddess) => {
                                return (
                                  <div className='sm:w-1/2 md:w-1/3 lg:w-1/4 pl-4 pr-4 pt-4 flex flex-col justify-center items-center' key={`goddess-${goddess.id}`}>
                                    <img className='w-full' alt={`goddess-${goddess.id}`} src={goddess.tokenURI}/>
                                    <div className='flex flex-row justify-center items-center mt-4'>
                                      <p className='font-epitaph font-bold mr-4'>CryptoVenus #{goddess.id}</p>
                                      <button 
                                        onClick={() => { 
                                          downloadResource(goddess.tokenURI, `cryptovenus-${goddess.id}.png`);
                                        }}
                                      >
                                        <img className='h-6 w-6' alt='download button' src='https://seekicon.com/free-icon-download/cloud-download_13.svg'/>
                                      </button>
                                    </div>
                                  </div>
                                )
                              })
                          }
                        </div>
                        <button className='font-epitaph cursor-pointer text-white bg-black pl-4 pr-4 pb-2 pt-2 mt-4 text-md rounded-lg' onClick={() => { this.setState({viewType: HomeViewType.MINT}) }}>Go Back</button>
                      </div>
                    )
                }
              })()
            ) : (
              <div className='flex flex-col w-72 justify-center items-center'>
                <div className='flex flex-row w-full justify-center items-center bg-black pt-6 pb-6 mt-12 rounded-lg cursor-pointer' onClick={() => this.connectWallet()}>
                  <p className='font-epitaph text-white text-center text-3xl'>Connect Wallet</p>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Home;
