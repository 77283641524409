import { Component, ReactNode } from 'react';
import packageJson from '../../../package.json';
const appVersion = packageJson.version;

const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
}

const refreshCacheAndReload = () => {
  console.log('SemVer mismatch. Clearing cache and hard reloading...')
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
}

type CacheBusterChildrenProps = { 
  loading: boolean 
  isLatestVersion: boolean 
  refreshCacheAndReload: Function 
}

type CacheBusterState = {
  loading: boolean
  isLatestVersion: boolean
  refreshCacheAndReload: Function
}

type CacheBusterProps = {
  children: (values: CacheBusterChildrenProps) => ReactNode
}

class CacheBuster extends Component<CacheBusterProps, CacheBusterState> {
  constructor(props: CacheBusterProps) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload
    }
  }

  async componentDidMount() {
    try {
      const response = await fetch('/meta.json');
      const meta: { version?: string } = await response.json();
      
      if (!meta.version) throw new Error('No version found in meta.json.');

      const latestVersion = meta.version!;
      const currentVersion = appVersion;

      const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

      if (shouldForceRefresh) {
        console.log(`Current Chalk WebApp version, ${currentVersion}, is out-of-date with latest version, (${latestVersion}). Invalidating cache and refreshing.`);
        this.setState({ loading: false, isLatestVersion: false })
      } else {
        console.log(`Running the latest version of Chalk WebApp - version: ${latestVersion}. No cache invalidation required.`);
        this.setState({ loading: false, isLatestVersion: true })
      }
    } catch (err) {
      console.error('Error checking current Chalk WebApp version with latest version. Not invalidating cache, but you might be running older version of client.', err);
      this.setState({ loading: false, isLatestVersion: true })
    }
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;

    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

export default CacheBuster;